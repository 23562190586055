
import {defineComponent, ref, computed, onMounted} from 'vue';
import { useStore } from '@/store';
import {ArticleMutationTypes} from "@/store/modules/articles/mutation-types";
import { Category, Status, Type } from '@/components/types/enums';
import ArticlesRow from "./ArticlesRow.vue";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import Spinner from '@/components/Spinner.vue';
import routerService from "@/services/routerService";
import {useRoute, useRouter} from "vue-router";
import SelectFieldsShowModal from '@/components/modals/SelectFieldsShowModal.vue';
import articlesService from '@/services/articles';
import timeService from '@/services/timeService';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import BasePagination from '@/components/ui/BasePagination.vue';

export default defineComponent({
  name: 'ArticlesTable',
  components: {
    BasePagination,
    SelectFieldsShowModal,
    ArticlesRow,
    BaseIcon,
    BaseSelect,
    SearchDropdown,
    Spinner
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const search: { [x: string]: any } = ref({
      PRODUCT_GROUP: null,
      STATUS: null,
      CATEGORY: null,
      ARTICLE: null,
      PRODUCT_SUB_GROUP: null,
      PRODUCT_SUB_SUB_GROUP: null,
      CODETYPE: null
    });
    const isLoading = computed(() => store.getters.getIsLoading);
    const articles: { [x: string]: any } = computed(() => store.getters.getArticles);
    const pageable: { [x: string]: any } = computed(() => store.getters.getPageable);
    const params: { [x: string]: any } = store.getters.getParameters;
    const selectedFields = ref(null) as any

    const isFieldSelect = (fieldType: string) => {
      if (selectedFields.value) {
        return selectedFields.value.findIndex((field: any) => field.type === fieldType) >= 0
      }

      return false
    }

    const header = computed(() => {
      const parameters = {
        ARTICLE: 'Артикул',
        PRODUCT_GROUP: 'Товарная группа',
        STATUS: 'Статус',
        CATEGORY: 'Категория',
      }

      if (selectedFields.value) {
        selectedFields.value.map((field: any) => {
          //@ts-ignore
          parameters[field.type] = field.name
        })
      }

      // let types: { [x: string]: any } = ArticleParameters;
      let types: { [x: string]: any } = parameters;
      return Object.keys(types).map(key => {
        return {[key]: types[key]}
      })
    });

    const convertData = computed(() => {
      let status: { [x: string]: string } = Status;
      let category: { [x: string]: string } = Category;
      return articles.value.content.map((o: any) => {
        const types: { [x: string]: any } = Type
        return [
          {
            id: o.articleId,
            name: o.status === 'REPLACED' && !!o.articleReplace
              ? `${o.article} (${o.articleReplace.article})`
              : `${o.article}`
          }, // Артикул
          o.productGroup, // Товарная группа
          o.status, // Статус
          o.category ? category[o.category] : null, // Категория
          ...(isFieldSelect('PRODUCT_SUBGROUP_1') ? [o.productSubgroup1] : []),
          ...(isFieldSelect('PRODUCT_SUBGROUP_2') ? [o.productSubgroup2] : []),
          ...(isFieldSelect('NAME') ? [o && o.name] : []),
          ...(isFieldSelect('COMMENT') ? [o && o.commet] : []),
          ...(isFieldSelect('APPLIC') ? [o && o.applic] : []),
          ...(isFieldSelect('APPLIC_COMMENT') ? [o && o.applicNotes] : []),
          ...(isFieldSelect('TYPE') ? [o && o.type && types[o.type]] : []),
          ...(isFieldSelect('PAIR')
              ? [
                  o.pairs && o.pairs.length
                    ? o.pairs[0].leftArticle === o.article
                        ? 'Левый'
                        : 'Правый'
                    : null
                ]
              : []
          ),
          ...(isFieldSelect('ANALOG')
            ? [o && o.analogs && o.analogs[0] && o.analogs.join(', ')]
            : []
          ),
        ]
      })
    });

    function setDefaultData() {
      if (!route.query?.page) {
        routerService.setQuery('page', 0, route.query, router)
      } else {
        store.commit(ArticleMutationTypes.SET_ARTICLES_PAGE, Number(route.query.page))
      }

      params.forEach((o: any) => {
        search.value[o.type] = o.value.desc ? o.value.desc : o.value
      });
      store.commit(ArticleMutationTypes.GET_ARTICLES, null)
    }

    function clearSelect(type: string) {
      search.value[type] = null
      store.commit(
        ArticleMutationTypes.DELETE_PARAMETER,
        [type]
      )
    }

    function update(item: string, value: any) {
      search.value[item] = value

      let payload = { type: item, value };
      store.commit(ArticleMutationTypes.SET_PARAMETER, payload);

      if (item === 'PRODUCT_GROUP' && !value) {
        search.value.PRODUCT_SUB_GROUP = null
        search.value.PRODUCT_SUB_SUB_GROUP = null

        localStorage.removeItem('PRODUCT_GROUP_FILTER_ITEM')
        localStorage.removeItem('PRODUCT_SUB_GROUP_FILTER_ITEM')
        localStorage.removeItem('PRODUCT_SUB_SUB_GROUP_FILTER_ITEM')

        store.commit(
          ArticleMutationTypes.DELETE_PARAMETER,
          ['PRODUCT_GROUP', 'PRODUCT_SUB_GROUP', 'PRODUCT_SUB_SUB_GROUP']
        );
      }

      if (item === 'PRODUCT_SUB_GROUP' && !value) {
        search.value.PRODUCT_SUB_SUB_GROUP = null

        localStorage.removeItem('PRODUCT_SUB_GROUP_FILTER_ITEM')
        localStorage.removeItem('PRODUCT_SUB_SUB_GROUP_FILTER_ITEM')

        store.commit(
          ArticleMutationTypes.DELETE_PARAMETER,
          ['PRODUCT_SUB_GROUP', 'PRODUCT_SUB_SUB_GROUP']
        );
      }
      routerService.setQuery('page', 0, route.query, router)
    }

    function changePage({ pageNumber }: { pageNumber: number}) {
      routerService.setQuery('page', pageNumber, route.query, router)
      store.commit(ArticleMutationTypes.CHANGE_PAGE, pageNumber);
    }

    function setPageable(numberPage: number) {
      store.commit(ArticleMutationTypes.SET_ARTICLES_PAGE, numberPage)
    }

    function scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }

    function clearState() {
      store.commit(ArticleMutationTypes.CLEAR_STATE, null)
    }

    function openSelectFieldModal() {
      const element = document.getElementById(`modal-fields`)!;
      element.style.display = 'flex'
    }

    function closeSelectFieldModal() {
      const element = document.getElementById(`modal-fields`)!;
      element.style.display = 'none'
    }

    function setShowFieldsInTable(fields: any) {
      selectedFields.value = fields
    }

    async function downloadExcelTable() {
      const checkedSelectors = selectedFields.value.filter((field: any) => {
        return field.checked
      })

      store.commit(ArticleMutationTypes.SET_LOADING, true)

      const getParamValue = (type: string) => {
        const indexParam = params.findIndex((param: any) => param.type === type)

        return indexParam >= 0
          ? params[indexParam].value
          : null
      }

      try {
        const table = await articlesService.exportSearchBySelectors({
          selectors: [
            ...checkedSelectors.map((selector: any) => selector.type)
          ],
          ...(getParamValue('CATEGORY') && { categories: [getParamValue('CATEGORY')] }),
          ...(getParamValue('PRODUCT_GROUP') && { productGroups: [getParamValue('PRODUCT_GROUP').id] }),
          ...(getParamValue('STATUS') && { statuses: [getParamValue('STATUS')] }),
        })

        let date = timeService.createDateForViewFromUTCTime();
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `articles_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        store.commit(ArticleMutationTypes.SET_LOADING, false)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (e) {
        store.commit(ArticleMutationTypes.SET_LOADING, false)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    }

    onMounted(async () => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
        await setDefaultData()
        openSelectFieldModal()
      } else {
        await router.push('login')
      }
    });

    return {
      isFieldSelect,
      openSelectFieldModal,
      downloadExcelTable,
      closeSelectFieldModal,
      setShowFieldsInTable,
      search,
      isLoading,
      articles,
      pageable,
      header,
      convertData,
      update,
      changePage,
      clearState,
      clearSelect
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   this.clearState();
  //   next()
  // }
})
