
import {defineComponent, computed} from 'vue';
import {useStore} from '@/store'
import BaseIcon from '@/components/ui/BaseIcon.vue';
import {ArticleMutationTypes} from "@/store/modules/articles/mutation-types";
import router from "@/router";
import {CardArticleMutationTypes} from "@/store/modules/article-card/mutation-types";

export default defineComponent({
  name: 'ArticlesRow',
  components: {
    BaseIcon
  },
  props: {
    row: {
      type: Array,
      required: true,
      default: () => []
    },
    isHeader: {
      type: Boolean,
      default: () => false
    }
  },
  setup() {
    const store = useStore();
    const sort = computed(() => store.getters.getSort);
    const getHeaderName = computed(() => {
      return (item: object) => Object.values(item)[0]
    });
    const getBodyName = computed(() => {
      return (item: any) => {
        if (item && typeof item === 'object') return item.name;
        else return item
      }
    });

    function setSort() {
      let payload = sort.value === 'asc' ? 'desc' : 'asc';
      store.commit(ArticleMutationTypes.SET_SORT, payload)
    }

    function goToArticle(item: any) {
      if (item.id) {
        localStorage.setItem('LAST_CHOSEN_ARTICLE_ID', item.id)
        router.push({name: 'article', params: {id: item.id}})
      }
    }

    const getLastChosenArticleId = computed(() => {
      return localStorage.getItem('LAST_CHOSEN_ARTICLE_ID')
    })

    return {getLastChosenArticleId, sort, getHeaderName, getBodyName, setSort, goToArticle}
  }
})
