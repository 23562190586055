
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import articles from '@/services/articles';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'SelectFieldsShowModal',
  emits: ['setShowFieldsInTable', 'closeModal'],
  components: { BaseCheckbox, ModalWrapper },
  data: () => ({
    fieldsForSelect: null as any
  }),
  methods: {
    setChecked(checkboxIndex: number) {
      this.fieldsForSelect[checkboxIndex].checked = !this.fieldsForSelect[checkboxIndex].checked;
    },
    async setFields() {
      const allCheckedFields = this.fieldsForSelect.filter((field: any) => field.checked)
      const checkedFieldTypes = allCheckedFields.map((field: any) => field.type)

      try {
        await articles.updateSelectors(checkedFieldTypes)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        this.$emit('setShowFieldsInTable', allCheckedFields)
        this.$emit('closeModal')
      } catch (e) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    }
  },
  async mounted() {
    const allSelectors = await articles.getAllSelectors()
    const currentUserSelectors = await articles.getCurrentUserSelectors()

    const isCheckedSelector = (selectorType: string) => {
      if (selectorType === 'ARTICLE' || selectorType === 'STATUS' || selectorType === 'PRODUCT_GROUP' || selectorType === 'CATEGORY') {
        return true
      }

      return Object.keys(currentUserSelectors).findIndex((currentUserSelectorType: string) => currentUserSelectorType === selectorType) >= 0
    }

    this.fieldsForSelect = Object.keys(allSelectors).map((selectorType: string, index: number) => {
      const selectorName = allSelectors[selectorType].toLowerCase()
      const ucFirst = selectorName[0].toUpperCase() + selectorName.slice(1)

      return {
        name: ucFirst,
        index,
        disabled: selectorType === 'ARTICLE' || selectorType === 'STATUS' || selectorType === 'PRODUCT_GROUP' || selectorType === 'CATEGORY',
        checked: isCheckedSelector(selectorType),
        type: selectorType
      }
    })

    const allCheckedFields = this.fieldsForSelect.filter((field: any) => field.checked)
    this.$emit('setShowFieldsInTable', allCheckedFields)
  }
})
